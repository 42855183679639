@import '../../util/variables';

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 7%;

  .nav__brand {
    width: 100px;
    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      animation: spin 1s linear infinite;
    }
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  li {
    text-decoration: none;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    font-weight: bold;

    a:hover,
    a:active {
      // color: $color-primary-1;
      color: #4158d0;
    }
  }

  .nav__brand {
    cursor: pointer;
    a:hover {
      color: $text-color-light;
    }
  }

  a {
    text-decoration: none;
    color: $text-color-light;
  }

  .nav__links {
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .nav__btn {
    display: none;
  }
  @media screen and (max-width: $screen-sm) {
    .nav__btn {
      display: block;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .nav__btn {
    &__checkbox {
      display: none;
    }

    &__button {
      background-color: $color-primary-1;
      height: 3rem;
      width: 3rem;
      position: fixed;
      top: 5rem;
      right: 2rem;
      border-radius: 50%;
      z-index: 2000;
      box-shadow: rgba($color-primary-1, 0.3) 0px 7px 29px 0px;
      text-align: center;
      cursor: pointer;
    }

    &__background {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      position: fixed;
      top: 5rem;
      right: 2.5rem;
      background-image: radial-gradient($text-color-dark, $color-primary-1);
      z-index: 1000;
      transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    }

    &__nav {
      height: 100vh;
      position: fixed;
      top: 0;
      right: -30%;
      z-index: 1500;

      opacity: 0;
      width: 0;
      transition: all 0.8s cubic-bezier(0.6, -0.55, 0.265, 1.55);
    }

    &__list {
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translate(-50%, -50%);
      list-style: none;
      text-align: center;
      display: flex;
      flex-direction: column;
      // width: 100%;
    }

    &__item {
      margin: 1rem;
    }

    &__link {
      font-size: 2rem;
      font-weight: bold;
      color: $text-color-light;
      text-decoration: none;

      &:hover,
      &:active {
        color: $color-primary-1;
      }
    }

    &__checkbox:checked ~ &__background {
      transform: scale(80);
    }

    &__checkbox:checked ~ &__nav {
      opacity: 1;
      width: 100%;
    }

    &__icon {
      position: relative;
      margin-top: 1.5rem;
      margin-left: 0.7rem;
      &,
      &::before,
      &::after {
        width: 1.5rem;
        height: 2px;
        background-color: $text-color-light;
        display: inline-block;
      }

      &,
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        transition: all 0.2s;
      }

      &::before {
        top: -0.4rem;
      }
      &::after {
        top: 0.4rem;
      }
    }

    &__button:hover &__icon::before {
      top: -0.6rem;
    }

    &__button:hover &__icon::after {
      top: 0.6rem;
    }

    &__checkbox:checked + &__button &__icon {
      background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon::before {
      top: 0;
      transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon::after {
      top: 0;
      transform: rotate(-135deg);
    }
  }
}
